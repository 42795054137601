<template>
  <div class="study_main_learn_area">
    <div class="study_main_learn_btn">학습결과 전체보기</div>
    <div class="study_main_learn_title">
      <img src="/image/sub/study_main_calendar_ic_03.png" alt="학습결과 아이콘" />
      <p>학습결과</p>
    </div>
    <ul @click.prevent="userRedirectToStudyResult" class="clickable">
      <li
        v-for="(result, key) in latestLearningResults"
        :key="`result-${key}`"
      >
        <a href="#">
          <p class="study_main_learn_list" v-text="result.content_title" v-if="result.content_title"></p>
          <p class="study_main_learn_list" v-text="result.content_name" v-else></p>
          <div class="study_main_learn_label01" v-if="result.quiz !== null && result.quiz.status === 'complete'">학습완료</div>
          <div class="study_main_learn_label02" v-else>학습중</div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'StudyRoom.StudyRoomMainLearnArea',
  props: {
    latestLearningResults: {
      type: [Object, Array],
      required: true
    },
    demo: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    userRedirectToStudyResult () {
      if (this.demo) {
        this.$router.replace({ name: 'demo.study-room.study-result' })
      } else {
        this.$router.replace({ name: 'study-room.study-result' })
      }
    }
  }
}
</script>

<style scoped>

</style>
