<template>
  <div class="study_main_week_area">
    <div class="study_main_week_title">
      <img src="/image/sub/study_main_calendar_ic_02.png" alt="" />이번주 학습일정
    </div>
    <product-display :products="classes" :demo="demo"></product-display>
  </div>
</template>

<script>

import ProductDisplay from '@/components/Product/ProductDisplay.vue'
export default {
  name: 'StudyRoom.StudyRoomMainWeeklyArea',
  components: {
    ProductDisplay
  },
  props: {
    classes: {
      type: [Object, Array],
      required: true
    },
    demo: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
