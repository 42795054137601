<template>
  <a
    href="#"
  >
    <ul>
      <li
        v-for="(product, key) in products"
        :key="`product-${key}`"
        :title="product.content_name"
        :sub-title="`${product.scheduled_at} [${product.start_time}:${product.end_time}]`"
        @click.prevent="userRedirectToStudyRoomContent(product)"
        :class="{
          'weekly-schedule-data-today': product.is_today,
        }"
      >
        <span v-text="product.content_title" v-if="product.content_title"></span>
        <span v-text="product.content_name" v-else></span>
        <span class="study-room-book-content-schedule" v-text="product.schedule"></span>
        <div class="clearfix"></div>
      </li>
    </ul>
  </a>
</template>

<script>

export default {
  name: 'ProductDisplay',
  props: {
    products: {
      type: Array,
      required: true
    },
    demo: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    userRedirectToStudyRoomContent (product) {
      if (this.demo) {
        this.$router.replace({ name: 'demo.study-room.content', params: { talkScheduleId: product.id } })
      } else {
        this.$router.replace({ name: 'study-room.content', params: { talkScheduleId: product.id } })
      }
    }
  }
}
</script>

<style scoped>
.study-room-book-content-schedule {
  font-size: 11px;
  float: right;
  margin-right: 10px;
  vertical-align: top;
}
</style>
